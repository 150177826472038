<!-- 店铺管理 -->
<template>
    <div>
        <div style="background: rgba(255,255,255,1);padding: 20px;" class="flex flex-justify-between flex-align-center">
            <div style="font-size:18px;font-weight:bold;color:rgba(41,41,41,1);">店铺管理</div>
        </div>
        <div class="flex">
            <div class="flex-1" style="overflow: auto;margin: 20px;background: white;">
                <div style="padding: 20px;">
                    <el-dropdown @command="createShop" style="margin-right: 25px;">
                        <el-button>添加店铺</el-button>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item :command="0">淘宝</el-dropdown-item>
                            <el-dropdown-item :command="1" :disabled="true">天猫</el-dropdown-item>
                            <el-dropdown-item :command="2" :disabled="true">京东</el-dropdown-item>
                            <el-dropdown-item :command="3" :disabled="true">拼多多</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                    <el-button @click="delRows">删除</el-button>
                </div>
                <div>
                    <el-table :data="rows" style="width: 100%" ref="_table">
                        <el-table-column type="selection" width="55">
                        </el-table-column>
                        <el-table-column prop="name" label="店铺名称">
                        </el-table-column>
                        <el-table-column prop="logo" label="logo" align="center">
                            <template slot-scope="scope">
                                <img v-if="scope.row.logo" :src="scope.row.logo" style="height:50px;">
                            </template>
                        </el-table-column>
                        <el-table-column prop="type" label="类型" width="100">
                            <template slot-scope="scope">
                                <img v-if="scope.row.type==0" src="@/assets/images/sale/mall/taobao.png">
                                <img v-if="scope.row.type==1" src="@/assets/images/sale/mall/tianmao.png">
                                <span v-if="scope.row.type==2">京东</span>
                                <img v-if="scope.row.type==3" src="@/assets/images/sale/mall/pinduoduo.png">
                                <span v-if="scope.row.type==4">有赞</span>
                                <span v-if="scope.row.type==5">微盟</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="绑定时间" width="200">
                            <template slot-scope="scope">
                                {{scope.row.createdAt | moment('YYYY-MM-DD HH:mm:ss') }}
                            </template>
                        </el-table-column>
                        <!-- <el-table-column label="操作" width="180px">
                            <template slot-scope="scope">
                                <el-button type="text" size="small" @click="goto('/sale/shop/info/'+scope.row.id)">
                                    <span class="icon iconfont icon-bianji1"></span>&nbsp;详情
                                </el-button>
                            </template>
                        </el-table-column> -->
                    </el-table>
                </div>
                <div style="text-align: right;">
                    <el-pagination background layout="prev, pager, next" :total="total" :current-page.sync="currentPage" :page-size="pageSize"></el-pagination>
                </div>
            </div>
        </div>
        <el-dialog :title="popTitle" :visible.sync="popVisible" :destroy-on-close="true" width="90%">
            <CreateTaoBaoShop v-if="createShopType==0"></CreateTaoBaoShop>
        </el-dialog>
    </div>
</template>

<script>
    import { query,delShopByIds } from '@/service/sale';
    import CreateTaoBaoShop from './taobao/Create.vue'
    export default {
        components: {
            CreateTaoBaoShop
        },
        data() {
            return {
                popVisible: false,
                popTitle: '',
                createShopType:null,
                popType: null,
                rows: [],
                currentPage:null,
                pageSize:20,
                total: 0
            }
        },
        created() {
            this.refreshRows();
            window.addEventListener("message", event=>{
                if(event.data=='shop_auth_ok'){
                    this.refreshRows();
                    this.$message.success('授权成功!');
                    this.popVisible=false;
                }
            }, false);
        },
        computed: {
            selectedRowIds: function () {
                let ids = this.$refs._table.store.states.selection.reduce((pre, cur) => {
                    pre.push(cur.id)
                    return pre;
                }, []);
                return ids;
            }
        },
        methods: {
            goto(path) {
                this.$router.push(path);
            },
            createShop(type){
                this.createShopType=type;
                if(type==0){
                    this.popTitle='添加淘宝店铺';
                    this.popVisible=true;
                }
            },
            refreshRows() {
                query({
                    shop:{
                        list:{
                            page:{
                                skip: ((this.currentPage||1)-1) * this.pageSize,
                                count: this.pageSize,
                                order: [['createdAt','desc']]
                            }
                        }
                    }
                }).then(resp=>{
                    this.rows=resp.shop.list.rows;
                    this.total=resp.shop.list.count;
                }).catch(err => {
                    this.$message.error(err.message);
                });
            },
            // 批量删除
            delRows() {
                if (this.selectedRowIds.length > 0) {
                        delShopByIds(this.selectedRowIds).then(()=>{
                            this.$message.success('删除成功');
                            this.refreshRows();
                        }).catch(err => {
                        this.$message.error(err.message);
                    });
                }
            },
            // 编辑
            popEditRow(row) {
                // this.$router.push('/contact_edit_user/'+row.id);
                // this.isEditingUser=true;
                // this.curEditUserId=row.id;
                // this.popType=2;
                this.popTitle = '编辑' + row.name;
                this.popVisible = true;
            },
            // 用户保存成功
            // onUserSaved(){
            //     this.popVisible=false;
            //     this.refreshMembers();
            // },
        }
    }
</script>
<style>

</style>
<style scoped>

</style>